<template>
	<section>
		<b-row class="mb-2">
		    <b-col lg="12">
				<Breadcrumb :labels="labels" />				
		    </b-col>		    
		</b-row>		
		<b-row>
		    <b-col md="12">
		    	<div class="relative">
			        <b-tabs card class="caption-add table-custom">                       
			            <b-tab title="Basic Info" active class="overview-tab">	
			            	<validation-observer
						        ref="form_rel"
						        #default="{invalid}" >
				                <b-form @submit.prevent class="profile-frm mb-20" style="border-radius: 5px; margin-top: 10px">
				                	<b-row class="align-center">
							            <b-col lg="6">
							            </b-col>
							            <b-col lg-6>
							            	<div class="text-right mb-2">
							            		<template v-if="userData">

								                    <b-button type="button" variant="basic" class="btn-df size-18 black" @click="cancelEdit" style="box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25); margin-right: 10px ">Cancel</b-button>
								                    <b-button type="button" variant="primary" class="btn-df size-18 black" @click="saveEdit">
								                        Save
								                    </b-button>
								                    <!-- <b-button type="button" variant="primary" class="btn-df size-18 black" v-else-if="isEditing" @click="editAction">
								                        Edit
								                    </b-button> -->

								                </template>
								                <template v-else>
								                	<b-button type="button" variant="primary" class="btn-df size-18 black btn-disabled-gray" disabled>
								                        Edit
								                    </b-button>
								                </template>
							                </div>
							            </b-col>
							        </b-row>				                
					                <div style="border-top: 1px solid #000000B2;" class="pb-2 mb-10"></div>
					                <div class="frm-basic size-16 black-child d-block">              
				                      	<b-row style="width: 100%; margin: 0;"> 
				                          	<b-col lg="6" class="px-0">             
					                            <b-form-group label="CSID" label-cols-lg="4" class="input-group-label">
				                                    <b-form-input
				                                    	v-model="showID"
				                                      	placeholder="CSID"
				                                      	readonly
				                                    ></b-form-input>
					                            </b-form-group>
				                          	</b-col> 	
				                          	<b-col lg="6" class="px-0">             
					                            <b-form-group label="Status" label-cols-lg="4" class="star-required">	
					                            	<validation-provider
					                                  #default="{ errors }"
					                                  name="Status"
					                                  vid="status"
					                                  rules="required"                      
					                                >
						                                <v-select
						                                	v-model= "statusDetailText"
						                                    label="title"
						                                    :options="selectStatus"
						                                    placeholder="Select status"
					                                    />
					                                    <small class="text-danger">{{ errors[0] }}</small>
					                                </validation-provider>
					                            </b-form-group>
				                          	</b-col> 
				                          	<b-col lg="6" class="px-0">             
				                              	<b-form-group label="Country Name" label-cols-lg="4" class="star-required">     
				                              		<validation-provider
					                                  #default="{ errors }"
					                                  name="Service Name"
					                                  vid="name"
					                                  rules="required"                
					                                >                  
					                                    <v-select
						                                	v-model= "detail.name"
						                                    label="country_name"
						                                    :options="selectCountry"
						                                    placeholder="Select Country"
					                                    />
					                                    <small class="text-danger">{{ errors[0] }}</small>
					                                </validation-provider>
				                              	</b-form-group>
				                          	</b-col>	
				                          	<b-col lg="6" class="px-0"> 
					                            <b-form-group label="Date Created" label-cols-lg="4">				                                    
				                                    <flat-pickr
				                                      	class="form-control"   
				                                      	v-model="detail.created_at"
				                                      	:config="{ altInput: true,altFormat: 'd/m/y', dateFormat: 'Y-m-d' }"
				                                      	placeholder="dd/mm/yy"
				                                      	disabled
				                                    />
					                            </b-form-group>
				                          	</b-col> 				                          	
				                          	<b-col lg="6" class="px-0">   
				                          		<b-form-group label="Country Code" label-cols-lg="4" class="input-group-label">            
				                          			<validation-provider
					                                  #default="{ errors }"
					                                  name="Country Code"
					                                  vid="dialling_code"
					                                  rules="required"                
					                                >    
							                            <b-form-input
							                            	v-model= "detail.country_code"
					                                      	placeholder="Code"
					                                      	readonly
						                                >
						                                </b-form-input>
						                                <small class="text-danger">{{ errors[0] }}</small>
						                            </validation-provider>
				                                </b-form-group>
				                          	</b-col>
				                      	</b-row>
					                </div>
					            </b-form> 
					        </validation-observer>
			            </b-tab>                     
			        </b-tabs>     
			    </div>
			</b-col>
		</b-row>
	</section>
</template>
<script>

	import Breadcrumb from '@/views/itees/admin-dashboard/partial/Breadcrumb';
    import vSelect from 'vue-select'
    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import { required, email, integer, min } from '@validations'
    import { togglePasswordVisibility } from '@core/mixins/ui/forms'
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
    import flatPickr from 'vue-flatpickr-component'
	import Datepicker from 'vuejs-datepicker'
	import globalAdmin from '../../model/globalAdmin';
	export default {
		mixins: [globalAdmin],
	  	components: {
	  		Breadcrumb,
          	vSelect,
          	ValidationProvider,
          	ValidationObserver,
          	flatPickr,
          	Datepicker
	  	},
	  	data() {
		    return {
		    	userData: JSON.parse(localStorage.getItem('userData')),
				labels: { "administrations_country_edit": "" },                
			    isEditing: true,
				permission: [],
			    selectStatus: [
			    	{title: 'Active', value: 1},
			    	{title: 'Inactive', value: 0},
			    ],
			    selectCountry: [
			    ],
			   	detail: [],
			   	statusDetailText: {
			   		title: null,
			   		value: null
			   	},
			   	showID: null,
            }
		},		
		created(){
			this.permission = this.checkPermission(this.userData,"Administration");
			this.listAllCountry()
			this.fetchItem()
		},
		watch: {            
            'detail.name'(val) {            	
               	if(val && val.dialling_code){
               		this.detail.country_code = val.dialling_code
               	}
               	if(val == null){
               		this.detail.country_code = null
               	}
            },     
        },
		methods: {
			editAction(){
				this.isDisabled = false
				this.isEditing = false
				this.disableField= ''
			},
			cancelEdit(){
				this.isEditing = true
				this.isDisabled = true
                this.disableField= 'disabled-field'
                this.$router.push({ name: 'staff_administrations', query: { tab: 3 } })
			},
			saveEdit(){				

				this.$refs.form_rel.validate().then(success => {

					if (success){

						let fields = {
		                  	name: this.detail.name.country_name ? this.detail.name.country_name : this.detail.name,
		                  	country_code: this.detail.country_code,         
		                  	status: this.statusDetailText.value
		                }
						this.$store
						.dispatch('auth/editCountry', 
							{
								id: this.$route.params.id,
								data: fields
							}
						)
						.then(response => {
							this.$toast({
			                      	component: ToastificationContent,
			                      	props: {
			                          title: fields.name + ' Updated!',
			                          variant: 'success',
			                      	},
			                	},
			                	{
			                      	position: 'top-center'
			                	}
			                )
			                this.$router.push({ name: 'staff_administrations', query: { tab: 3 } })
						})
						.catch((error) => {
							console.log(error)
							this.$toast({
			                      	component: ToastificationContent,
			                      	props: {
			                          title: error.response.data.message,
			                          variant: 'danger',
			                      	},
			                	},
			                	{
			                      	position: 'top-center'
			                	}
			                )
						})
					}
				})
			},
			listAllCountry(){
				this.$store
				.dispatch('auth/fetchAllCountry', {	
				})
				.then(response => {
					this.selectCountry = response.data.data
				})
				.catch((error) => {
					console.log(error)
				})
			},	
			fetchItem(){
				this.$store
				.dispatch('auth/detailCountry', {
					id: this.$route.params.id	
				})
				.then(response => {
					this.detail = response.data.data;
					// console.log('detail country: ', this.detail)
					this.labels['administrations_country_edit'] = this.detail.name
					document.title = response.data.data.name + ' - ITEES | Your Global Partner of Choice'		

					if(this.detail.status == 0) {
						this.statusDetailText.title = 'Inactive'
						this.statusDetailText.value = 0					
					}
					if(this.detail.status == 1) {						
						this.statusDetailText.title = 'Active'			
						this.statusDetailText.value = 1			
					}
					if(this.detail.id < 10){
						this.showID = 'CS000' + this.detail.id
					}
					if(this.detail.id > 9 && this.detail.id <= 99){
						this.showID = 'CS00' + this.detail.id
					}
					if(this.detail.id > 99 && this.detail.id <= 999){
						this.showID = 'CS0' + this.detail.id
					}
					if(this.detail.id > 999){
						this.showID = 'CS' + this.detail.id
					}
				})
				.catch((error) => {
					console.log(error)
				})
			},	
		},
	};	

</script>

<style lang="scss">
	@import '@core/scss/vue/libs/vue-flatpicker.scss';
	@import "@/assets/scss/_ite_tab_table.scss";
	
	.Vue-Toastification__container.top-center {
	    top: 20px; 
	}
	@media only screen and (min-width: 1401px){
		.options_list .vs__selected-options > input, .icon-search-wrapper input{
			height: 50px;
		}
	}
	@media only screen and (min-width: 1200px){
		.options_list .vs__selected-options > input, .icon-search-wrapper input{
			height: 40px;
		}
	}	
</style>